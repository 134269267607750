.navbar {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  border-bottom: 1px solid grey;
}
.audit-search {
  width: 260px;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 5px;
  font-size: 14px;
  padding-left:23px ;
}
.audit-search:focus-visible{
  outline: none;
}
.navbar-left-section {
  display: flex;
  align-items: center;
  gap: 13px;
  width: 43%;
}
.status-Filter-values{
  overflow: hidden;
}
.navbar-left-section .navbar-left-checkbox{
  margin-left: 14px;
}
.recall-btn{
  background-color: #4999C9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
}
.navbar-left-section input[type="text"],
.navbar-left-section select {
  border: none;
  outline: none;
}
.workflow-refresh-btn {
  border: 1px solid #a4d0ed;
  padding: 6px;
  border-radius: 10px;
  background-color: #4094c8;
  /* color: #4094c8; */
  /* width: 150px; */
  display: flex;
  justify-content: space-around;
}
.workflow-refresh-btn {
  cursor: pointer;
  font-size: 14px;
}
.workflow-refresh-btn span {
  display: flex;
  width: 14px;
  height: 14px;
}
.workflow-refresh-btn span img {
  width: 100%;
}
.navbar-right-section {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 9px;
  width: 57%;
  padding-left: 15px;
}

.filter-wrapper {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  display: flex;
  /* gap: 6px; */
  /* font-family: sans-serif; */
  color: #fff;
  background-color: #4999C9;
  font-size: 14px;
}

.assgine-container{
  position: relative;
}

.assgine-btn {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 9px;
  display: flex;
  color: #475467;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

.assgine-btn img{
  margin-left: 10px;
}

.assgine-btn:hover{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 9px;
  display: flex;
  color: #475467;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
}
.assgine-btn.open {
  background-color: transparent; 
  color: #475467;
  border: 1px solid #ddd;
}

.assgine-btn:active, .btn:first-child:active{
  background-color: transparent; 
  color: #475467;
  border: 1px solid #ddd;
}

.assgine-modal-section {
  position: absolute;
  z-index: 20;
  background-color: #ffffff;
  padding: 20px 0px 0px;
  top: 34;
  left: 0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(4, 4, 4, 0.2);
  min-width: 180px;
  min-height: 200px;
  max-height: 400px;
  box-sizing: border-box;
  border: none;
}
.assgine-dropdown-item{
  padding: 0px;
}

.assigne-search-section {
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0px 6px 0px 12px;
  background-color: #f2f2f2;
  margin: 0px 20px;
}

.assigne-search-filter-section{
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0px 6px 0px 12px;
  background-color: #cfe2ff;
}

.assigne-search-icon {
  padding-right: 5px;
}

.assgine-search-input {
  outline: none;
  border: none;
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: 400;
  width: 210px;
  height: 40px;
}

.assgine-search-filter-input{
  outline: none;
  border: none;
  background-color: #cfe2ff;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 40px;
}

.assgine-search-filter-input::placeholder{
  color: #344054;
  font-weight: 400;
  font-family: sans-serif;
}

.assgine-search-input::placeholder {
  color: #344054;
  font-weight: 400;
  font-family: sans-serif;
}

.assgine-modal-body{
  width: 100%;
  max-height: 260px;
  overflow-y: auto;
  scroll-behavior: smooth;
  margin-top: 10px;
}
.assgine-modal-body::-webkit-scrollbar{
  width: 7px;
  height: 199.79px;
}

.assgine-modal-body::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

.assgine-modal-body::-webkit-scrollbar-thumb {
  background-color: #a4a4a4;
  border-radius: 10px;
}

.assgine-modal-body::-webkit-scrollbar-thumb:hover {
  background-color: #a4a4a4;
}

.assgine-modal-body::-webkit-scrollbar-corner {
  background-color: #fff;
}

.assigne-user-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 5px; */
  padding: 10px 18px;
}

.assgineusers-not-found{
  padding: 10px 18px;
}
.assigne-user-section:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}
.assigne-user-section.active {
  background-color: #dcf2ff;
}
.assigne-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.assigne-user-email-card{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-left: 14px;
}

.assgine-user-icon {
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1px #ffffff;
  height: 35px;
  width: 35px;
}

.assgine-user-name-icon {
  border-radius: 50%;
  background-color: #6b7280;
  color: #ffffff;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  /* font-weight: 300; */
  box-shadow: 0px 0px 0px 1px #ffffff;
}

.assign-username {
  font-size: 13px;
  /* padding-left: 14px; */
  font-weight: 600;
  line-height: 20px;
  font-family: sans-serif;
}
.assigne-card p{
  margin: 0;
}

.assign-email{
  font-size: 12px;
  font-weight: 600;
}

.no-usersfound{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.assgine-actions-btn {
  display: flex;
  justify-content: end;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  bottom: 0;
  border-radius: 8px;
  padding: 10px;
  background: #ffffff;
}

.assigne-save-btn {
  background-color: #4999c9;
  border: none;
  padding: 10px 50px;
  color: #fff;
  border-radius: 10px;
}

.status-value {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 2px;
  font-size: 14px;

}
.priority-value {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 2px;
  font-size: 14px;

}
p.ellpsis{
  max-width: 110px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
.insurer-value {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 2px;
  font-size: 14px;

}
.close-filter-btn:hover{
cursor: pointer;
}
.filter-wrapper:hover {
  cursor: pointer;
}
.modal-footer .btn-primary {
  background-color: #032f49;
  border-color: #032f49;
}
.modal-footer .btn-primary:hover {
  background-color: #032f49;
  border-color: #032f49;
}

.search-bar {
  position: relative;
}
.search-icon {
  position: absolute;
  left: 5px;
  top: calc(50% - 7px);
  width: 15px;
  height: 15px;
} 
.search-recommendations {
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow: hidden scroll;
  top: 34px;
  z-index: 2;
  /* padding: 12px 12px 12px 0px; */
  background-color: #fff;
}
.search-recommendations li {
  list-style-type: none;
  padding-left: 5px;
  padding-bottom: 5px;
}
.search-recommendation {
  /* background-color: #a4d0ed; */
  border: 1px solid #ddd;
}
.search-recommendation:hover {
  background-color: #4999C9;
  color: #fff;
  cursor: pointer;
}
.hcp-file-dropdown-webworkflow .btn-primary{
  background-color: transparent;
  color: #000;
  border: none;
}
.webworkflow-dropdown-menu{
  max-height: 400px;  
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 8px;
  padding-right: 8px;
  width: max-content;
}
.webworkflow-dropdown-menu .form-check{
  width: 400px;
  font-size: 14px;
}
.date-picker-wrapper{
  width: 100% !important;
  flex-wrap: wrap;
}
.load-filters::-webkit-scrollbar {
  width: 6px;
  height: 5px; 
}

.load-filters::-webkit-scrollbar-track {
  background: #f1f1f1;  
}

.load-filters::-webkit-scrollbar-thumb {
  background: #888;  
  border-radius: 6px;  
}

.load-filters::-webkit-scrollbar-thumb:hover {
   cursor: pointer; 
}

/* filter design */
.filters-border{
  /* border-right: 1px solid rgb(215, 214, 214); */
  /* padding: 0 16px 16px; */
}

.accordion-body-content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.filters-modal-header{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 20px 20px 5px;
  margin: 0px;
  border-bottom: 1px solid #d7d6d6;
}

.filter-title{
  font-family: "Founders Grotesk";
}

.filter-input{
  width: 100%;
  height: 38px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  padding-left: 5px;
  outline: none;
}

.filter-input:focus-visible{
  border: 1px solid #D0D5DD;
}
.file-type-wrapper {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;  
}
.checkbox-container input {
  margin-right: 8px;
  height: 16px;
  width: 16px;
  border: 1px solid #D0D5DD;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 18px 24px; /* Space between items */
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input {
  margin-right: 8px;
}

.filter-modal-body > div {
  /* border: 1px solid #E6EDF4; */
}

.filters-border  h6 {
  white-space: nowrap;
  font-size: 14px;
  background-color: #fff;
  position: sticky;
  top: 0;
  padding: 12px 0px;
}

.checkbox-container label {
  white-space: nowrap;
}

.dates-wrapper {
  padding: 10px;
  width: 50%;
}

.dates-wrapper > h6 {
  white-space: nowrap;
}

.dates-wrapper .date-picker-wrapper {
  display: flex;
  justify-content: space-between;
  /* gap: 50px; */
  position: relative;
}

.dates-wrapper .date-picker-wrapper > div {
  /* max-width: 96px; */
  /* width: 96px; */
  height: 38px;
  position: relative;
}

.dates-wrapper .date-picker-wrapper > div.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

.dates-wrapper .date-picker-wrapper > div:nth-child(1)::after {
  content: "";
  border-bottom: 1px solid #667085;
  position: absolute;
  width: 10px;
  top: 19px;
  right: -30px;
}

.dates-wrapper .date-picker-wrapper > div input {
  /* width: 100%; */
  height: 38px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  text-align: center;
}

.datepicker-tooltip {
  position: relative;
  cursor: pointer; 
}
.datepicker-tooltip:hover::after {
  content: attr(data-title); 
  position: absolute; 
  bottom: 100%;
  left: 50%; 
  transform: translateX(-50%); 
  background-color: #575757; 
  color: #fff; 
  padding: 5px 10px; 
  border-radius: 4px; 
  font-size: 12px; 
  white-space: nowrap;
  opacity: 0.5; 
  pointer-events: none;
  visibility: hidden; 
}
.datepicker-tooltip:hover::after {
  opacity: 1; 
  visibility: visible;
}


.date-header {
  margin-top: 24px;
}
.filter-footer{
  display: flex;
  justify-content: end;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  bottom: 0;
  border-radius: 8px;
  right: 0;
  padding: 10px 20px;
  z-index: 10;
  border-top: 1px solid #d7d6d6;
}

.filter-btn-save {
  background-color: #4999c9;
  border: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 10px;
}
.filter-btn-cancel {
  background-color: #6c757d;
  border: none;
  padding: 10px 13px;
  color: #fff;
  border-radius: 10px;
  margin-right: 10px;
}
.toggle-conversion-btn {
  border: 1px solid;
  border-radius: 6px;
  background: #504e4e;
  color: #fff;
  font-size: 12px;
  /* margin-left: 8px; */
}
.load-filters{
  max-height: 100px;
    overflow-y: auto;
    /* max-width: 320px; */
}

.search-recommendations::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.search-recommendations::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7A7C82; 
  border-radius: 10px;
}
 
.search-recommendations::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

.search-recommendations::-webkit-scrollbar-thumb:hover {
  background: gray; 
  cursor: pointer;
}

.modalfilters{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modalfilters-section{
  position: relative;
  transform: translate(50%, 30%);
  background-color: #f2f2f2;
  /* padding: 20px 0px; */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(4, 4, 4, 0.2);
  min-width: 600px;
  height: 250px;
  max-width: 600px;
  box-sizing: border-box;
}

/* .audit-log-btn{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
  display: flex;
  font-size: 14px;
  color: #475467;
}

.audit-log-btn:hover{
  cursor: pointer;
} */


.left-filter {
  width: 50%;
  overflow-y: auto;
  max-height: 500px;
}

.left-filter::-webkit-scrollbar {
  width: 8px;
}

.left-filter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7a7c82;
  border-radius: 10px;
}

.left-filter::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.left-filter::-webkit-scrollbar-thumb:hover {
  background: darkgray;
  cursor: pointer;
}


/* accordion item styles */
.item_accordion {
  border: none;
  overflow-y: auto;
  max-height: 250px;
  margin-right: 10px;
}

.item_accordion_user{
  border: none;
  /* max-height: 250px; */
  margin-right: 10px;
}
.item_accordion::-webkit-scrollbar {
  width: 8px;
}

.item_accordion::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7a7c82;
  border-radius: 10px;
}

.item_accordion::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.item_accordion::-webkit-scrollbar-thumb:hover {
  background: darkgray;
  cursor: pointer;
}


.item_accordion .accordion-button::after{
  transform: rotate(270deg); 
  transition: transform 0.2s ease-in-out;
  color: #E6EDF4;
}

.item_accordion .accordion-button[aria-expanded="true"]::after {
  transform: rotate(0deg);
}


.item_accordion_user .accordion-button::after{
  transform: rotate(270deg); 
  transition: transform 0.2s ease-in-out;
  color: #E6EDF4;
}

.item_accordion_user .accordion-button[aria-expanded="true"]::after {
  transform: rotate(0deg);
}

.item_accordion .accordion-header {
  position: sticky;  
  top: 0;             
  z-index: 1;         
  background-color: white; 
}

.header_accordion .accordion-button {
  font-size: 15px; 
  font-weight: 500;
  margin-bottom: 3px;
  position: relative;
  z-index: 1;
  margin: 0px;
}

.body_accordion{
  padding: 5px 25px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
}

.accordion-input{
  margin-right: 8px;
  height: 15px;
  width: 15px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.accordion-label{
  font-size: 14px;
}

.body_accordion1{
  padding: 5px 5px;
}

/* .accordion-body{
  padding: 6px 10px;
  margin-top: 10px;
}


.custom-accordion-item .accordion-body {
  max-height: 200px;
  overflow-y: auto;
}

.custom-accordion-item .accordion-header {
  position: sticky;  
  top: 0;             
  z-index: 1;         
  background-color: white; 
} */

/* loading modal */

.loading-assigne-modal-overlay-fade{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-modal-content-section {
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(4, 4, 4, 0.2);
  height: 100px;
  width: 500px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.loading-modal-content-section p{
  font-size: 18px;
  margin: 0;
}